import React, {
  useRef, useState, useCallback,
} from 'react';
import {
  Table, Checkbox, Button, message, Popconfirm,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getFunctions, httpsCallable } from 'firebase/functions';

import ApproveUserModal from './ApproveUserModal';
import type { UserObj } from '../../types';
import useSearchFilter from '../../hooks/useSearchFilter';
import dayjs from 'dayjs';
import useAdminUsers from '../../hooks/useAdminUsers';

const { Column, ColumnGroup } = Table;

const AdminUsers = () => {

  const [deleting, setDeleting] = useState<boolean | string>(false);
  const emailFilterRef = useRef(null);
  const nameFilterRef = useRef(null);
  const [showApproveUserModal, setShowApproveUserModal] = useState<boolean>(false);

  const { loading, users, loadUsers, togglePermission } = useAdminUsers();

  const deleteAdmin = useCallback(async (user: UserObj) => {
    setDeleting(user.uid);

    try {
      const deleteAdminUser = httpsCallable<{ uid: string }>(getFunctions(), 'deleteAdminUser');
      await deleteAdminUser({
        uid: user.uid,
      });
      message.success('Successfully deleted user.');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while deleting user.');
    }

    await loadUsers();
    setDeleting(false);
  }, [loadUsers]);

  const toggleApproveUserModal = useCallback(() => {
    setShowApproveUserModal((visible) => !visible);
  }, []);

  const { filterDropdown, searchFilterIcon, filterDropdownProps } = useSearchFilter();

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={toggleApproveUserModal}
          disabled={loading}
          icon={<PlusOutlined />}
        >
          Approve new admin
        </Button>
      </div>
      <br />
      <Table
        size="small"
        loading={loading}
        dataSource={users}
        rowKey={(user: UserObj) => user.email}
        locale={{ emptyText: loading ? 'Loading...' : 'Sorry, you do not have any users.' }}
      >
        <Column
          title="Photo"
          dataIndex="photoURL"
          render={(photoURL) => (<img src={photoURL} alt="" height="80" width="80" />)}
        />
        <Column
          title="Name"
          dataIndex="displayName"
          sorter={(
            a: UserObj, b: UserObj,
          ) => (a.displayName ? a.displayName.localeCompare(b.displayName || '') : 0)}
          defaultSortOrder="ascend"
          filterDropdown={filterDropdown('name', nameFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, user: UserObj) => Boolean(user
            .displayName?.toLowerCase().includes((value as string).toLowerCase()))}
          filterDropdownProps={filterDropdownProps(nameFilterRef)}
        />
        <Column
          title="Email"
          dataIndex="email"
          sorter={(a: UserObj, b: UserObj) => a.email.localeCompare(b.email)}
          render={(email) => email}
          filterDropdown={filterDropdown('email', emailFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, user) => user
            .email.toLowerCase().includes((value as string).toLowerCase())}
          filterDropdownProps={filterDropdownProps(emailFilterRef)}
        />
        <Column
          title="Created"
          dataIndex="metadata.creationTime"
          sorter={(
            a: UserObj, b: UserObj,
          ) => dayjs(a.metadata.creationTime).valueOf()
            - dayjs(b.metadata.creationTime).valueOf()}
          render={(empty, user) => dayjs(user.metadata.creationTime).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          title="Last Sign In"
          dataIndex="metadata.lastSignInTime"
          sorter={(
            a: UserObj, b: UserObj,
          ) => dayjs(a.metadata.lastSignInTime).valueOf()
            - dayjs(b.metadata.lastSignInTime).valueOf()}
          render={(empty, user) => dayjs(user.metadata.lastSignInTime).format('YYYY-MM-DD HH:mm')}
        />
        <ColumnGroup
          title="Permissions"
        >
          <Column
            title="Content"
            dataIndex="customClaims.edit_content"
            render={(empty, user: UserObj) => (
              <Checkbox
                checked={Boolean(user.customClaims && user.customClaims.edit_content)}
                onChange={() => togglePermission(user, 'edit_content')}
                disabled={deleting === user.uid}
              />
            )}
          />
          <Column
            title="Donations"
            dataIndex="customClaims.access_donations"
            render={(empty, user: UserObj) => (
              <Checkbox
                checked={Boolean(user.customClaims && user.customClaims.access_donations)}
                onChange={() => togglePermission(user, 'access_donations')}
                disabled={deleting === user.uid}
              />
            )}
          />
          <Column
            title="Users"
            dataIndex="customClaims.manage_users"
            render={(empty, user: UserObj) => (
              <Checkbox
                checked={Boolean(user.customClaims && user.customClaims.manage_users)}
                onChange={() => togglePermission(user, 'manage_users')}
                disabled={deleting === user.uid}
              />
            )}
          />
        </ColumnGroup>
        <Column
          title="Actions"
          render={(_, user: UserObj) => (
            <Popconfirm
              title={`Are you sure you want to delete "${user.displayName}"?`}
              onConfirm={() => deleteAdmin(user)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                ghost
                size="small"
                icon={<DeleteOutlined />}
                title="Delete user"
                disabled={Boolean(deleting)}
                loading={deleting === user.uid}
              />
            </Popconfirm>
          )}
        />
      </Table>
      {showApproveUserModal && (
        <ApproveUserModal
          users={users}
          onSuccess={loadUsers}
          onClose={toggleApproveUserModal}
        />
      )}
    </>
  );
};

export default AdminUsers;
