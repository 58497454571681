import { FieldValue } from 'firebase/firestore';
import type { MenuItem } from '../types';

export const tabOptions = {
  resources: 'Resources',
  talks: 'Talks',
  meditations: 'Meditations',
  extras: 'Extras',
  about: 'About',
};

export enum FirestoreCollection {
  CATEGORIES = 'categories',
  DOCS = 'docs',
  FOLDERS = 'folders',
  METADATA = 'metadata',
  SUBTITLES = 'subtitles',
  TOPICS = 'topics',
  TOPICS_POTENTIAL = 'topics-potential',
  TRANSIENTS = 'transients',
  QUOTES = 'quotes',
  CONTRIBUTORS = 'contributors',
  SCHEDULED_QUOTES = 'scheduledQuotes',
}

export const vimeoGuidelinesUrl = 'https://docs.google.com/document/d/1wK1rkpdKIUIp0600XD-GLUcX2OSD9NUSqDF54dYT7Xo/edit?usp=sharing';

export const quotesGuidelinesUrl = 'https://docs.google.com/document/d/1_p1pA3e0NPM_Qd41tCI8h5ZtxNLLwHx5rwWulYagWP0/edit?tab=t.0';
export const quotesVariationsUrl = 'https://docs.google.com/document/d/1_p1pA3e0NPM_Qd41tCI8h5ZtxNLLwHx5rwWulYagWP0/edit?tab=t.46fpcoyvyh7f';

export const menuItems: Array<MenuItem> = [
  ...Object.keys(tabOptions).filter((t) => t !== 'about' && t !== 'extras').map((key) => ({
    slug: key,
    menuTitle: tabOptions[key as keyof typeof tabOptions],
    pageTitle: tabOptions[key as keyof typeof tabOptions],
  })),
  {
    slug: 'media',
    menuTitle: 'Audio',
    pageTitle: 'Audio',
  },
  {
    slug: 'video',
    menuTitle: 'Video',
    pageTitle: 'Video',
  },
  {
    slug: 'contributors',
    menuTitle: 'Contributors',
    pageTitle: 'Contributors',
  },
  {
    slug: 'quotes',
    menuTitle: 'Quotes',
    pageTitle: 'Quote Management',
  },
  {
    slug: 'metadata',
    menuTitle: 'Metadata',
    pageTitle: 'Metadata',
  },
  {
    slug: 'subtitles',
    menuTitle: 'Subtitles',
    pageTitle: 'Subtitles',
  },
  {
    slug: 'settings',
    menuTitle: 'Settings',
    pageTitle: 'Settings',
  },
  {
    slug: 'crm',
    menuTitle: 'Donations',
    pageTitle: 'Donations & Donors',
  },
  {
    slug: 'link-generator',
    menuTitle: 'Link Generator',
    pageTitle: 'Link Generator',
  },
  {
    slug: 'activity',
    menuTitle: 'Activity',
    pageTitle: 'Activity',
  },
  {
    slug: 'sync-podcasts',
    menuTitle: 'Sync Podcasts',
    pageTitle: 'Sync Podcasts',
  },
  {
    slug: 'folder-thumbs',
    menuTitle: 'Folder Thumbs',
    pageTitle: 'Folder Thumbnails',
  },
  {
    slug: 'users',
    menuTitle: 'Users',
    pageTitle: 'User Management',
  },
];

export const docTypeOptions = {
  text: 'Text',
  audio: 'Audio',
  video: 'Video',
};

export const visibilityOptions = {
  private: 'Private',
  public: 'Public',
};

export const languageOptions = {
  bg: 'Bulgarian',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  pt: 'Portuguese',
  th: 'Thai',
  vi: 'Vietnamese',
};

export const slugifyConfig = {
  replacement: '-',
  remove: /[~!@#$%^&*()+|\\{}'";:?/>.<,]/g,
  lower: true,
  trim: false,
};

export const firebaseFunctionsBaseUrl = process.env.NODE_ENV === 'production'
  ? 'https://us-central1-plum-village.cloudfunctions.net'
  : 'http://localhost:5001/plum-village/us-central1';export const supportedLanguageCodes = ['en', 'bg', 'fr', 'de', 'it', 'pt', 'es', 'th', 'vi'] as const;

export type SupportedLanguageCode = (typeof supportedLanguageCodes)[number];

export const languageNames: {
  [key in SupportedLanguageCode]: string;
} = {
  ...languageOptions,
};
type ContributorBase = {
  [key in SupportedLanguageCode]?: string;
};

export interface Contributor extends ContributorBase {
  en: string;
  id: string;
  slug: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
}

