import React from 'react';
import { Tabs } from 'antd';
import { Helmet } from 'react-helmet-async';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import AdminUsers from './AdminUsers';
import AppUsers from './AppUsers';

const Users = () => {
  const tabItems = [
    {
      label: 'Users',
      key: 'users',
      children: (
        <ErrorBoundary>
          <AppUsers />
        </ErrorBoundary>
      ),
    },
    {
      label: 'Admins',
      key: 'admins',
      children: (
        <ErrorBoundary>
          <AdminUsers />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage requiredPermission="manage_users" fullwidth>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <Tabs defaultActiveKey="users" items={tabItems} />
    </AuthedPage>
  );
};

export default Users;
