import React, {
  useEffect, useCallback, useState,
} from 'react';
import {
  Form, Input, message, Modal,
} from 'antd';
import { generateUniqueSlug } from '../../utils';
import { FileObj } from '../../types';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';

const { Item: FormItem } = Form;

type Props = {
  uneditedFile: FileObj | null,
  handleEditCancel: () => void,
  isSaving: boolean,
  setIsSaving: (isSaving: boolean) => void,
  handleSavedFile: (updatedFile: FileObj, isNewFile: boolean) => void,
};

const FolderEditModal = ({
  uneditedFile, isSaving, handleEditCancel, setIsSaving, handleSavedFile,
} : Props) => {
  const [file, setFile] = useState<FileObj | null>(null);
  const [formErrors, setFormErrors] = useState<{ title?: string, slug?: string }>({});

  useEffect(() => {
    setFile(uneditedFile);
    setFormErrors({});
  }, [uneditedFile]);

  const validateForm = useCallback((): boolean => {
    if (!file) {
      return false;
    }

    // reset form errors
    const newFormErrors: { title?: string, slug?: string } = {};

    if (!file.title) {
      newFormErrors.title = 'Title is required';
    }

    setFormErrors(newFormErrors);

    return Object.keys(newFormErrors).length === 0;
  }, [file]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleFieldChange = useCallback((key: string, value: string | boolean) => {
    if (!file) {
      return;
    }

    const val = key === 'slug' ? generateUniqueSlug(value as string) : value;

    setFile({ ...file, [key]: val });
  }, [file]);

  const handleSave = useCallback(async () => {
    if (!file) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setIsSaving(true);

    const ref = doc(collection(getFirestore(), 'files'), file.id);

    try {
      await setDoc(ref, file);
      handleSavedFile(file, false);
      setFormErrors({});
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while saving the file.');
      setIsSaving(false);
    }
  }, [validateForm, setIsSaving, file, handleSavedFile]);

  const savingDisabled = Object.keys(formErrors).length > 0;

  return (
    <Modal
      title={file ? `Edit ${file.title}` : ''}
      open={file !== null}
      confirmLoading={isSaving}
      okButtonProps={{ disabled: savingDisabled }}
      cancelButtonProps={{ disabled: isSaving }}
      onOk={handleSave}
      onCancel={handleEditCancel}
      width={600}
    >
      <FormItem
        label="Name"
        validateStatus={formErrors.title ? 'error' : undefined}
        help={formErrors.title}
      >
        <Input
          value={file ? file.title : ''}
          onChange={(event) => handleFieldChange('title', event.target.value)}
          disabled={isSaving}
        />
      </FormItem>
      <FormItem
        label="Storage URL"
      >
        <Input
          value={file ? file.url : ''}
          disabled={true}
        />
      </FormItem>
      <FormItem
        label="CDN URL"
      >
        <Input
          value={file ? file.cdnUrl : ''}
          disabled={true}
        />
      </FormItem>
      <FormItem
        label="Filename"
      >
        <Input
          value={file ? file.name : ''}
          disabled={true}
        />
      </FormItem>
      <FormItem
        label="Database ID"
      >
        <Input
          value={file ? file.id : ''}
          disabled={true}
        />
      </FormItem>
    </Modal>
  );
};

export default FolderEditModal;
