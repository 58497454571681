import { Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Spin, message } from 'antd';
import { collection, deleteDoc, doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { generateUniqueSlug } from '../../utils';
import AuthedPage from '../AuthedPage';
import { Contributor, languageNames, SupportedLanguageCode, supportedLanguageCodes } from '../../config';
import { FirestoreCollection } from '../../config';

export type LanguageTranslation = {
  [key in SupportedLanguageCode] : string
};

const { Item: FormItem } = Form;

type FormErrors = {
  [key in SupportedLanguageCode]?: (string | undefined);
};

const ContributorEdit = () => {

  const itemId = useParams<'itemId'>().itemId;
  const [item, setItem] = useState<Partial<Contributor>>();
  const [slugs, setSlugs] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const navigate = useNavigate();

  const isNew = useMemo(() => itemId === 'new', [itemId]);

  const deleteConfirmTitle = useMemo(()=> item
    ? (
      <span>
        <span>Are you sure you want to delete this contributor?</span>
      </span>
    )
    : '', [item]);

  const handleChange = useCallback((key: string, value: string) => {
    if (!item) {
      return;
    }

    setItem({ ...item, [key]: value });

  }, [item]);

  const validateForm = useCallback(() => {
    if (!item) {
      return false;
    }

    const newFormErrors: FormErrors = {};

    if (!item.en) {
      newFormErrors.en = 'Contributor\'s name in English is required';
    } else if (slugs.includes(generateUniqueSlug(item.en.trim()))) {
      newFormErrors.en = 'English name must be unique.';
    }
    setFormErrors(newFormErrors);

    return Object.keys(newFormErrors).length === 0;
  }, [item, slugs]);

  const save = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsSaving(true);

    if (!item.en) {
      throw new Error('Contributor name in English can not be nullish.');
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id, ...rest
    } = item;

    const slug = generateUniqueSlug(item.en.trim());

    const createdAt = isNew ? { createdAt: serverTimestamp() } : {};

    const data: Omit<Partial<Contributor>, 'id'> =  {
      ...rest,
      ...createdAt,
      slug,
      updatedAt: serverTimestamp(),
    };

    const contributorsCollection = collection(getFirestore(), FirestoreCollection.CONTRIBUTORS);

    const ref = isNew ? doc(contributorsCollection) : doc(contributorsCollection, itemId);

    try {
      await setDoc(ref, data);

      const updatedContributor: Partial<Contributor> = { id: ref.id, ...data };

      setItem(updatedContributor);

      message.success('Successfully saved contributor');

    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while saving the contributor');
    } finally {
      setIsSaving(false);
      navigate('/contributors');
    }
  }, [item, isNew, itemId, navigate]);

  const handleSave = useCallback(async () => {
    if (!item) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    save();

  }, [item, validateForm, save]);

  const handleDelete = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteDoc(doc(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS), item.id));

      message.success('Successfully deleted the contributor.');
      navigate('/contributors');
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the contributor.');
    }
  }, [item, navigate]);

  const initialLoad = useCallback(async () => {

    const currentContributorPromise = new Promise<Partial<Contributor>>((resolve, reject) => {
      if (itemId === 'new') {
        resolve({
          id: itemId,
        });
      } else {
        getDoc(doc(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS), itemId)).then((dataSnapshot) => {

          if (dataSnapshot.metadata.fromCache) {
            throw new Error('No Internet!');
          }

          const contributorData = dataSnapshot.data() as Omit<Contributor, 'id'>;

          if (dataSnapshot.exists()) {
            const id = dataSnapshot.id;
            resolve( {
              id: id,
              ...contributorData,
            });

          } else {
            throw new Error('Not found.');
          }
        }).catch((error) => {
          reject(error);
        });
      }
    });

    const [currentMetadata, slugsSnapshot] = await Promise.all([
      currentContributorPromise,
      getDoc(doc(collection(getFirestore(), FirestoreCollection.TRANSIENTS), 'contributor-slugs')),
    ]);

    const data = slugsSnapshot.data();
    if (data && (data as { list: string[] })?.list) {
      setSlugs((data as { list: string[] }).list.filter((slug) => slug !== currentMetadata.slug));
    }

    setItem(currentMetadata);
    setIsLoading(false);
  }, [itemId]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      {item && (
        <Helmet>
          <title>{item.en}</title>
        </Helmet>
      )}
      <Spin spinning={isLoading} delay={2000}>
        <div className="DocActionButtons DocActionButtons--top">
          <div className="DocActionButtons__container">
            <h1>{item?.en}</h1>
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
        <Divider />
        <div className="DocEdit__container">
          <Row
            gutter={{
              xs: 8, sm: 16, md: 24, lg: 32,
            }}
          >
            <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
              <Card>
                <Form
                  layout="horizontal"
                >
                  {
                    supportedLanguageCodes.map(code =>
                      <FormItem
                        label={languageNames[code]}
                        tooltip={`${code === 'en' ? 'Required.' : ''} Contributor's name in ${languageNames[code]}`}
                        validateStatus={formErrors[code] ? 'error' : ''}
                        help={formErrors[code]}
                      >
                        <Input
                          value={item?.[code]}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(code, event.target.value)}
                          disabled={isSaving || isDeleting}
                        />
                      </FormItem>,
                    )
                  }
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className="DocActionButtons DocActionButtons--bottom">
          <div className="DocActionButtons__container">
            {!isNew && (
              <Popconfirm
                title={deleteConfirmTitle}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  ghost
                  size="large"
                  loading={isDeleting}
                  disabled={isLoading || isSaving}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      </Spin>
    </AuthedPage>
  );
};

export default ContributorEdit;
