import { useCallback, useEffect, useState } from 'react';
import { ScheduledQuote } from './ScheduledQuotes';
import { collection, doc, DocumentReference, getDoc, getFirestore } from 'firebase/firestore';
import { FirestoreCollection, languageNames, supportedLanguageCodes } from '../../config';
import { Link, useParams } from 'react-router-dom';
import AuthedPage from '../AuthedPage';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Divider, Row, Spin } from 'antd';

const goToQuoteMarkup = (quoteRef: DocumentReference | undefined) =>
  quoteRef &&
    <Row>
      <Link to={`/quotes/quotes/${quoteRef.id}`}>
        Go to Quote details
      </Link>

    </Row>;

const ScheduledQuoteDetail = () => {
  const [item, setItem] = useState<ScheduledQuote>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const itemId = useParams<'itemId'>().itemId;

  const initialLoad = useCallback(async () => {

    let scheduledQuote: ScheduledQuote;
    const dataSnapshot = await getDoc(doc(collection(getFirestore(), FirestoreCollection.SCHEDULED_QUOTES), itemId));
    if (dataSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const quoteData = dataSnapshot.data() as Omit<ScheduledQuote, 'id'>;
    if (dataSnapshot.exists()) {
      const id = dataSnapshot.id;
      scheduledQuote = {
        id,
        ...quoteData,
      };
    } else {
      throw new Error('Not found.');
    }

    setItem(scheduledQuote);
    setIsLoading(false);
  }, [itemId]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      <Helmet>
        <title>{'Scheduled Quote details'}</title>
      </Helmet>
      <Spin spinning={isLoading} delay={2000}>
        <div className="DocActionButtons DocActionButtons--top">
          <div className="DocActionButtons__container">
            <h1>Quote scheduled for {item?.id}</h1>
          </div>
        </div>
        <Divider />
        {
          supportedLanguageCodes.map(code =>
            <Row
              gutter={{
                xs: 8, sm: 16, md: 24, lg: 32,
              }}
              className='QuoteEdit__language'
            >
              <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
                <Card
                  title={languageNames[code]}
                  extra={goToQuoteMarkup(item?.[code]?.quoteRef)}
                >
                  {
                    item?.[code] &&
                      (
                        <div>
                          <h3>Author</h3>
                          <div>
                            <span>{item?.[code].authorName}</span>
                          </div>
                          <Divider />
                          <h3>Quote variations</h3>
                          {
                            item?.[code]?.variations.map((variation) =>
                              <div>
                                <span>{variation}</span>
                              </div>,
                            )
                          }
                        </div>
                      )
                  }
                </Card>
              </Col>
            </Row>,
          )
        }
      </Spin>
    </AuthedPage>
  );

};

export default ScheduledQuoteDetail;
