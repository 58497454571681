import { useCallback, useEffect, useRef, useState } from 'react';
import { FirestoreCollection, languageNames, SupportedLanguageCode, supportedLanguageCodes } from '../../config';
import { collection, DocumentReference, getDocs, getFirestore, query } from 'firebase/firestore';
import { Button, InputRef, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import useSearchFilter from '../../hooks/useSearchFilter';
import { Link } from 'react-router-dom';


const getViewURL = (item: ScheduledQuote) => {
  return `/quotes/scheduled/${item.id}`;
};

export type ScheduledQuoteInfo = {
  authorName: string;
  quoteRef: DocumentReference;
  variations: string[];
};

type ScheduledQuoteInfoByLanguage = {
  [key in SupportedLanguageCode]?: ScheduledQuoteInfo;
};

export interface ScheduledQuote extends ScheduledQuoteInfoByLanguage {
  id: string;
}

const ScheduledQuotes = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduledQuotes, setScheduledQuotes] = useState<Array<ScheduledQuote>>([]);
  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, filterDropdownProps } = useSearchFilter();

  const columnRenderer = useCallback((values: string[]): JSX.Element => {
    return (
      <>{
        values.map(value => 
          <div key={value} style={{ whiteSpace: 'nowrap' }}>
            {renderHighlight(value)}
          </div>,
        )}
      </>
    );
  }, [renderHighlight]);

  const reloadScheduledQuotes = useCallback(async () => {

    setLoading(true);
    const scheduledQuotesSnapshot = await getDocs(query(collection(getFirestore(), FirestoreCollection.SCHEDULED_QUOTES)));

    if (scheduledQuotesSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const loadingScheduledQuotes: Array<ScheduledQuote> = [];

    scheduledQuotesSnapshot.forEach((scheduledQuoteItem) => {
      const scheduledQuoteData = scheduledQuoteItem.data() as Omit<ScheduledQuote, 'id'>;
      const scheduledQuoteId = scheduledQuoteItem.id;
      const scheduledQuote: ScheduledQuote = {
        id: scheduledQuoteId,
        ...scheduledQuoteData,
      };

      loadingScheduledQuotes.push(scheduledQuote);
    });
    loadingScheduledQuotes.sort((a, b) => Date.parse(b.id) - Date.parse(a.id));
    setScheduledQuotes(loadingScheduledQuotes);
    setLoading(false);
  }, []);


  useEffect(() => {
    reloadScheduledQuotes();
  }, [reloadScheduledQuotes]);

  return (
    <>
      <div className="TableHeader__wrapper">
        <Button
          type="primary"
          ghost
          loading={loading}
          icon={<ReloadOutlined />}
          onClick={reloadScheduledQuotes}
        >
          Reload
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={scheduledQuotes}
        rowKey={(item: ScheduledQuote) => item.id}
        locale={{ emptyText: searchText ? 'Nothing found matching your search' : 'Sorry, you do not have any scheduled quotes (yet).' }}
        scroll={{ x: true }}
      >
        <Table.Column
          title="#"
          dataIndex=""
          width={'5%'}
          render={(text: string, quote: ScheduledQuote) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {scheduledQuotes.indexOf(quote) + 1}
            </span>
          )}
        />
        <Table.Column
          title="Date (YYYY-MM-DD)"
          dataIndex=""
          filterDropdown={filterDropdown('title', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, item: ScheduledQuote) => item.id.toLowerCase().includes((value as string).toLowerCase()) ?? false}
          filterDropdownProps={filterDropdownProps(searchInput)}
          render={(text: string, quote: ScheduledQuote) => {
            const content = renderHighlight(quote.id);
            return (
              <Link to={getViewURL(quote)}>
                {content}
              </Link>
            );
          }}
          sorter={(a, b) => Date.parse(a.id) - Date.parse(b.id)}
        />
        {
          supportedLanguageCodes.map(code =>
            <Table.Column
              key={code}
              title={languageNames[code]}
              dataIndex=""
              filterDropdown={filterDropdown('title', searchInput)}
              filterIcon={searchFilterIcon}
              onFilter={(value, item: ScheduledQuote) => item[code]?.variations.join(' ').toLowerCase().includes((value as string).toLowerCase()) ?? false}
              filterDropdownProps={filterDropdownProps(searchInput)}
              render={(quote: ScheduledQuote) =>
                quote[code] ? columnRenderer(quote[code].variations ?? []) : <></>
              }
            />,
          )
        }
      </Table>
    </>
  );
};

export default ScheduledQuotes;
