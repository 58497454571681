import { Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Spin, message } from 'antd';
import { collection, deleteDoc, doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize, generateUniqueSlug } from '../../utils';
import AuthedPage from '../AuthedPage';
import { MetadataType } from './Metadata';
import { SupportedLanguageCode } from '../../config';
import { metadataNameOnPVApp, metadataNameSingular, metadataTabkeys } from './MetadataWrapper';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirestoreCollection } from '../../config';

export type LanguageTranslation = {
  [key in SupportedLanguageCode] : string
};

const { Item: FormItem } = Form;

type FormErrors = {
  en?: string,
};

const MetadataEdit = () => {

  const params = useParams();
  const { metadataType, metadataId } = params as { metadataType: keyof typeof metadataTabkeys, metadataId: string };
  const [item, setItem] = useState<MetadataType>();
  const [slugs, setSlugs] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [originalSlug, setOriginalSlug] = useState<string>();
  const [originalEnglishName, setOriginalEnglishName] = useState<string>();

  const navigate = useNavigate();

  const isNew = useMemo(() => metadataId === 'new', [metadataId]);

  const isItemEdited = useMemo(() => !isNew && (item && generateUniqueSlug(item.en.trim()) !== originalSlug), [isNew, item, originalSlug]);


  const savingDisabled = useMemo(() => isLoading
    || isDeleting
    || Object.keys(formErrors).length > 0
    || (!isNew && !isItemEdited),
  [isLoading, isDeleting, formErrors, isNew, isItemEdited]);

  const deleteConfirmTitle = useMemo(()=> item
    ? (
      <span>
        <span>Are you sure you want to delete </span>
        <span>{metadataNameSingular[metadataType]}: </span>
        <strong>{originalEnglishName}</strong>?
      </span>
    )
    : '', [item, originalEnglishName, metadataType]);

  const handleChange = useCallback((key: string, value: string) => {
    if (!item) {
      return;
    }

    setItem({ ...item, [key]: value });

  }, [item]);

  const validateForm = useCallback(() => {
    if (!item) {
      return false;
    }

    const newFormErrors: FormErrors = {};

    if (!item.en) {
      newFormErrors.en = `${capitalize(metadataNameSingular[metadataType])} name in English is required`;
    } else if (slugs.includes(generateUniqueSlug(item.en.trim()))) {
      newFormErrors.en = `English name must be unique in ${metadataType}`;
    }
    setFormErrors(newFormErrors);

    return Object.keys(newFormErrors).length === 0;
  }, [item, metadataType, slugs]);


  const getMTs = async (text: string): Promise<LanguageTranslation> => {
    const machineTranslations = httpsCallable<unknown, LanguageTranslation>(getFunctions(), 'metadataTranslations-machineTranslations');

    const result = await machineTranslations({ translate: text });
    return result.data;
  };

  const save = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsSaving(true);

    const {
      id : itemId, ...rest
    } = item;

    const slug = generateUniqueSlug(item.en.trim());

    let mts = {};
    if (isNew || isItemEdited) {
      try {
        mts = await getMTs(item.en);
      } catch (err) {
        console.error(err);
        message.warning(`Could not load translations for ${item.en}`, 5);
      }
    }
    const createdAt = isNew ? { createdAt: serverTimestamp() } : {};

    const data: Omit<MetadataType, 'id'> =  {
      ...rest,
      ...mts,
      ...createdAt,
      slug,
      updatedAt: serverTimestamp(),
      lastUpdatedBy: 'ADMIN',
    };

    const categoriesCollection = collection(getFirestore(), metadataType);

    const ref = isNew ? doc(categoriesCollection) : doc(categoriesCollection, itemId);

    try {
      await setDoc(ref, data);

      const updatedMetadata: MetadataType = { id: ref.id, ...data };

      setItem(updatedMetadata);

      message.success(`Successfully saved ${metadataNameSingular[metadataType]}`);

    } catch (err) {
      console.error(err);
      message.error(`Oops! something went wrong while saving the ${metadataNameSingular[metadataType]}`);
    } finally {
      setIsSaving(false);
      navigate(`/metadata/${metadataType}`);
    }
  }, [item, navigate, metadataType, isNew, isItemEdited]);

  const handleSave = useCallback(async () => {
    if (!item) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    save();

  }, [item, validateForm, save]);

  const handleDelete = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteDoc(doc(collection(getFirestore(), metadataType), item.id));

      message.success(`Successfully deleted the ${metadataNameSingular[metadataType]}.`);
      navigate(`/metadata/${metadataType}`);
    } catch (err) {
      console.error(err);
      message.error(`Oops! something went wrong while deleting the ${metadataNameSingular[metadataType]}.`);
    }
  }, [item, navigate, metadataType]);

  const initialLoad = useCallback(async () => {

    const currentMetadataPromise = new Promise<MetadataType>((resolve, reject) => {
      if (metadataId === 'new') {
        resolve({
          id: metadataId,
          slug: '',
          en: '',
        });
      } else {
        getDoc(doc(collection(getFirestore(), metadataType), metadataId)).then((dataSnapshot) => {
          if (dataSnapshot.metadata.fromCache) {
            throw new Error('No Internet!');
          }

          const metadataData = dataSnapshot.data() as Omit<MetadataType, 'id'>;

          if (dataSnapshot.exists()) {
            const id = dataSnapshot.id;
            resolve({
              id: id,
              ...metadataData,
            });

          } else {
            throw new Error('Not found.');
          }
        }).catch((error) => {
          reject(error);
        });
      }
    });

    const [currentMetadata, slugsSnapshot] = await Promise.all([
      currentMetadataPromise,
      getDoc(doc(collection(getFirestore(), FirestoreCollection.TRANSIENTS), `${metadataNameSingular[metadataType]}-slugs`)),
    ]);

    const data = slugsSnapshot.data();
    if (data && (data as { list: string[] })?.list) {
      setSlugs((data as { list: string[] }).list.filter((slug) => slug !== currentMetadata.slug));
    }

    setOriginalSlug(currentMetadata.slug);
    setOriginalEnglishName(currentMetadata.en);
    setItem(currentMetadata);
    setIsLoading(false);
  }, [metadataId, metadataType, setOriginalSlug]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      {item && (
        <Helmet>
          <title>{item.en}</title>
        </Helmet>
      )}
      <Spin spinning={isLoading} delay={2000}>
        <div className="DocActionButtons DocActionButtons--top">
          <div className="DocActionButtons__container">
            { isNew ? <h1>{item?.en}</h1> : <h1>{originalEnglishName} to <i>{item?.en}</i></h1>}
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              loading={isSaving}
              disabled={savingDisabled}
            >
              Save
            </Button>
          </div>
        </div>
        <Divider />
        <div className="DocEdit__container">
          <Row
            gutter={{
              xs: 8, sm: 16, md: 24, lg: 32,
            }}
          >
            <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
              <Card>
                <Form
                  layout="horizontal"
                >
                  <FormItem
                    label="English"
                    tooltip={`Required. This will be shown as the filter name within ${metadataNameOnPVApp[metadataType]} filters when user's interface language is English.`}
                    validateStatus={formErrors.en ? 'error' : ''}
                    help={formErrors.en}
                  >
                    <Input
                      value={item?.en}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange('en', event.target.value)}
                      disabled={isSaving || isDeleting}
                    />
                  </FormItem>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className="DocActionButtons DocActionButtons--bottom">
          <div className="DocActionButtons__container">
            {!isNew && (
              <Popconfirm
                title={deleteConfirmTitle}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  ghost
                  size="large"
                  loading={isDeleting}
                  disabled={isLoading || isSaving}
                >
                  Delete {metadataNameSingular[metadataType]}
                </Button>
              </Popconfirm>
            )}
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              loading={isSaving}
              disabled={savingDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      </Spin>
    </AuthedPage>
  );
};

export default MetadataEdit;
