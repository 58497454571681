import { collection, deleteDoc, doc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Contributor, FirestoreCollection, languageNames, supportedLanguageCodes } from '../../config';
import { Button, Divider, InputRef, message, Popconfirm, Table } from 'antd';
import useSearchFilter from '../../hooks/useSearchFilter';
import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AuthedPage from '../AuthedPage';

const getEditUrl = (item: Contributor) => {
  return `/contributors/${item.id}`;
};

const Contributors = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [contributors, setContributors] = useState<Array<Contributor>>([]);
  const [deleting, setDeleting] = useState<Array<string>>([]);
  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, filterDropdownProps } = useSearchFilter();
  const navigate = useNavigate();

  const columnRenderer = useCallback((value: string): JSX.Element => {
    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        {renderHighlight(value)}
      </div>
    );
  }, [renderHighlight]);

  const reloadContributors = useCallback(async () => {

    setLoading(true);
    const contributorsSnapshot = await getDocs(query(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS)));

    if (contributorsSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const loadingContributors: Array<Contributor> = [];

    contributorsSnapshot.forEach((quoteItem) => {
      const contributorData = quoteItem.data() as Omit<Contributor, 'id'>;
      const contributorId = quoteItem.id;
      const contributor: Contributor = {
        id: contributorId,
        ...contributorData,
      };

      loadingContributors.push(contributor);
    });

    setContributors(loadingContributors);
    setLoading(false);
  }, []);

  useEffect(() => {
    reloadContributors();
  }, [reloadContributors]);

  const handleDelete = useCallback(async (item: Contributor) => {
    setDeleting([...deleting, item.id]);
    const docRef = doc(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS), item.id);

    try {
      const quotesSnapshot = await getDocs(query(collection(getFirestore(), FirestoreCollection.QUOTES), where('authorRef', '==', docRef)));
      if (quotesSnapshot.metadata.fromCache) {
        throw new Error('No Internet!');
      }
      if (quotesSnapshot.size >= 1) {
        message.error(`Currently, there are one or more Quotes that are attributed to ${item.en}. 
            Please associate a new author with such quote(s) before deleting this Contributor.`, 20);
        return;
      }
      await deleteDoc(docRef);
      setContributors((prevDocs) => prevDocs.filter(({ id }) => id !== item.id));
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the contributor.');
    } finally {
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    }
  }, [deleting]);


  return (
    <AuthedPage fullwidth requiredPermission="edit_content">
      <div className="TableHeader__wrapper">
        <Button
          type="primary"
          ghost
          loading={loading}
          icon={<ReloadOutlined />}
          onClick={reloadContributors}
        >
          Reload
        </Button>
        <Button
          type="primary"
          onClick={() => navigate('/contributors/new')}
          disabled={loading}
          icon={<PlusOutlined />}
        >
          Add new
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={contributors}
        rowKey={(item: Contributor) => item.id}
        locale={{ emptyText: searchText ? 'Nothing found matching your search' : 'Sorry, you do not have any contributors (yet).' }}
        scroll={{ x: true }}
      >
        <Table.Column
          title="#"
          dataIndex=""
          width={'5.5%'}
          render={(text: string, item: Contributor) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {contributors.indexOf(item) + 1}
            </span>
          )}
        />
        <Table.Column
          title="Actions"
          key="actions"
          width={'13.5%'}
          render={(text: string, item: Contributor) => {
            const { id } = item;
            const isBeingDeleted = deleting.includes(id);
            const confirmTitle = (
              <span>
                Are you sure you want to delete contributor - <strong>{item.en}</strong>?
              </span>
            );

            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => navigate(getEditUrl(item))}
                  disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={confirmTitle}
                  onConfirm={() => handleDelete(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    size="small"
                    ghost
                    icon={<DeleteOutlined />}
                    loading={isBeingDeleted}
                  />
                </Popconfirm>
              </div>
            );
          }}
        />
        {
          supportedLanguageCodes.map(code =>
            <Table.Column
              title={languageNames[code]}
              dataIndex=""
              width={'13.5%'}
              filterDropdown={filterDropdown('title', searchInput)}
              filterIcon={searchFilterIcon}
              onFilter={(value, item: Contributor) => item[code]?.includes((value as string).toLowerCase()) ?? false}
              filterDropdownProps={filterDropdownProps(searchInput)}
              render={(item: Contributor) =>
                item[code] ? columnRenderer(item[code] ?? '') : <></>
              }
            />,
          )
        }
      </Table>
    </AuthedPage>
  );
};

export default Contributors;
