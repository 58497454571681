import React from 'react';
import {
  Form, Spin, Divider, InputNumber, Button,
} from 'antd';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import AuthedPage from './AuthedPage';
import useSettings from '../hooks/useSettings';

const { Item: FormItem } = Form;

const Actions = styled.div`
  text-align: right;
`;

const Settings = () => {
  const { settings, handleSettingChange, isSettingsLoading, handleSaveSettings, isSavingSettings } = useSettings();

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <Spin spinning={isSettingsLoading} delay={200}>
        <FormItem
          label="Max doc title length"
          extra="Maximum character length for a doc title"
        >
          <InputNumber
            min={1}
            step={1}
            value={settings.maxDocTitleLength}
            onChange={(value) => handleSettingChange('maxDocTitleLength', value)}
          />
        </FormItem>
        <FormItem
          label="Max folder title length"
          extra="Maximum character length for a folder title"
        >
          <InputNumber
            min={1}
            step={1}
            value={settings.maxFolderTitleLength}
            onChange={(value) => handleSettingChange('maxFolderTitleLength', value)}
          />
        </FormItem>
        <FormItem
          label="Max folder “Short Intro” length"
          extra="Maximum character length for a folder's short intro"
        >
          <InputNumber
            min={1}
            step={1}
            value={settings.maxFolderIntroLength}
            onChange={(value) => handleSettingChange('maxFolderIntroLength', value)}
          />
        </FormItem>

        <Divider />

        <Actions>
          <Button
            type="primary"
            size="large"
            onClick={handleSaveSettings}
            loading={isSavingSettings}
            disabled={isSettingsLoading}
          >
            Save
          </Button>
        </Actions>

      </Spin>
    </AuthedPage>
  );
};

export default Settings;
