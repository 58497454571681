import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Card, Spin, Tag, Table, Space, Input, Button,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { getFirestore, collection, setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

import AuthedPage from '../AuthedPage';
import type { CustomerObj, PaymentObj } from '../../types';
import {
  getCustomersMap,
  getPaymentStatusColour, getPaymentStatusDescription,
  loadPayments,
} from './paymentHelpers';

const { Column } = Table;

const getNotesDoc = (customerId: string) => {
  const db = getFirestore();
  return doc(collection(db, 'donor-notes'), customerId.toLowerCase());
};

type CustomerParams = {
  customerId: string,
};

const SingleCustomer = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [customer, setCustomer] = useState<CustomerObj | null>(null);
  const [notes, setNotes] = useState<string>();
  const [isSaved, setIsSaved] = useState<boolean>(true);

  const { customerId } = useParams<CustomerParams>();

  useEffect(() => {
    if (customerId) {
      const loadCustomer = async () => {
        loadPayments(null, null, customerId).then((payments) => {
          const customerMap = getCustomersMap(payments);
          setCustomer(customerMap[customerId.toLowerCase()]);
          setLoading(false);
        });
      };
      loadCustomer();

      getDoc(getNotesDoc(customerId)).then((snap) => {
        setNotes(snap.data()?.notes || '');
      });
    }
  }, [customerId]);

  const onNotesChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setNotes(e.target.value);
      setIsSaved(false);
    },
    []);

  const saveNotes = useCallback(() => {
    if (customerId) {
      setDoc(getNotesDoc(customerId), {
        notes,
        updated: serverTimestamp(),
      }).then(() => {
        setIsSaved(true);
      });
    }
  }, [notes, customerId]);

  return (
    <AuthedPage fullwidth requiredPermission="access_donations">
      <Spin spinning={loading} delay={200}>
        {customer ? (
          <>
            <Space>
              <Space direction='horizontal'>
                <Card title="Customer Name" size="small">
                  {customer.name}
                </Card>
                <Card title="Email" size="small">
                  {customer.email}
                </Card>
                <Card title="Country" size="small">
                  {customer.countryCode}
                </Card>
                <Card title="Donations Total" size="small">
                  {customer.total} {customer.currencies}
                </Card>
              </Space>
            </Space>
            <Space>
              <Card title="Notes" size="small">
                <Space>
                  <Input.TextArea
                    value={notes}
                    onChange={onNotesChange}
                    placeholder='Enter any notes on this donor for safekeeping'
                    cols={60}
                  />
                  <Button disabled={isSaved} onClick={saveNotes}>Save</Button>
                </Space>
              </Card>
            </Space>
            <Space>
              <Card title="Donations">
                <Table
                  loading={loading}
                  dataSource={customer.payments}
                  rowKey={(payment: PaymentObj) => payment.id}
                  locale={{ emptyText: 'No donations (yet).' }}
                  scroll={{ x: 600 }}
                >
                  <Column
                    title="Date"
                    dataIndex="paymentDate"
                    render={(paymentDate, payment) => (
                      <Link to={`/crm/payments/${payment.id}`} title="View donation">
                        {dayjs(paymentDate).format('YYYY-MM-DD HH:mm')}
                      </Link>
                    )}
                    sorter={
                      (a: PaymentObj, b: PaymentObj) => a.paymentDate.localeCompare(b.paymentDate)
                    }
                    defaultSortOrder="descend"
                  />
                  <Column
                    title="Amount"
                    dataIndex="amount"
                    sorter={(a: PaymentObj, b: PaymentObj) => a.amount - b.amount}
                    render={(amount, payment) => `${amount} ${payment.currency}`}
                  />
                  <Column
                    title="Status"
                    dataIndex="status"
                    render={(status, payment: PaymentObj) => (
                      <Tag
                        color={getPaymentStatusColour(payment.status)}
                        title={getPaymentStatusDescription(payment.status)}
                      >
                        {payment.status}
                      </Tag>
                    )}
                  />
                  <Column
                    title="Subscription?"
                    dataIndex="isSubscription"
                    render={(isSubscription) => (isSubscription ? 'Yes' : 'No')}
                  />
                  <Column
                    title="Platform"
                    dataIndex="platform"
                  />
                </Table>
              </Card>
            </Space>
          </>
        ) : (
          <>{loading ? 'Loading...' : 'Donor not found'}</>
        )}
      </Spin>
    </AuthedPage>
  );
};

export default SingleCustomer;
