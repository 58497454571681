import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button, Table, Divider, Popconfirm, message, List, Tag, InputRef,
} from 'antd';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';

import type {
  DocObj,
  DocType,
  Visibility,
  TabbedPageParams,
} from '../../../types';
import { getItemLanguage, languageName, renderDuration, sortCompare } from '../../../utils';
import VisibilityTag from '../../VisibilityTag';
import Header from './Header';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, Timestamp, where } from 'firebase/firestore';
import useFolders from '../../../hooks/useFolders';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { languageOptions } from '../../../config';
import { useFolderColumnFilterItems } from '../../../hooks/useFolderColumnFilterItems';
import { DocsFilterContext } from '../DocsAndFolders';
import { exportAndDownloadCsv } from '../../../csv';

type DocWithFolderTitle = DocObj & {
  folderTitle: string,
  folderTitles: { [folderId: string]: { folderTitle: string } }
};

const iconsByType: { [key: string]: FontAwesomeIconProps['icon'] } = {
  text: 'file-alt',
  audio: 'headphones',
  video: 'video',
};

const { Column } = Table;

const getEditUrl = (item: DocObj | string) => {
  if (typeof item === 'string') {
    return `/${item}/docs/new`;
  }

  const { tab, id } = item;

  return `/${tab}/docs/${id}`;
};

const Docs = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [docs, setDocs] = useState<Array<DocWithFolderTitle>>([]);
  const [deleting, setDeleting] = useState<Array<string>>([]);
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  const { tab } = useParams<TabbedPageParams>();
  const { folders, isLoadingFolders } = useFolders(tab);

  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, filterDropdownProps } = useSearchFilter();


  const reloadDocs = useCallback(async () => {
    if (isLoadingFolders) {
      return true;
    }

    setLoading(true);

    const docsSnapshot = await getDocs(query(collection(getFirestore(), 'docs'), where('tab', '==', tab)));

    if (docsSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }

    let loadingDocs: Array<DocWithFolderTitle> = [];
    docsSnapshot.forEach((item) => {
      const docData = item.data() as Omit<DocObj, 'id'>;
      const primaryFolder = docData.folder ? folders.find(
        (f) => f.id === docData.folder,
      ) : undefined;
      const folderTitle = primaryFolder?.title || '';
      const folderTitles = Object.keys(docData.folders || {})
        .reduce((
          foldersDict: DocWithFolderTitle['folderTitles'],
          folderId: string,
        ) => {
          const folder = folders.find((f) => f.id === folderId);
          // eslint-disable-next-line no-param-reassign
          foldersDict[folderId] = {
            folderTitle: folder ? folder.title : '',
          };
          return foldersDict;
        }, {});
      const order = docData.order || 0;

      loadingDocs.push({
        id: item.id,
        ...docData,
        folderTitle,
        folderTitles,
        order,
      });
    });

    loadingDocs = loadingDocs.sort(sortCompare);

    setDocs(loadingDocs);
    setLoading(false);
  }, [tab, isLoadingFolders, folders]);

  useEffect(() => {
    reloadDocs();
  }, [reloadDocs]);

  const handleDelete = useCallback(async (item: DocObj) => {
    setDeleting([...deleting, item.id]);

    try {
      await deleteDoc(doc(collection(getFirestore(), 'docs'), item.id));
      setDocs((prevDocs) => prevDocs.filter(({ id }) => id !== item.id));
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting doc.');
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    }
  }, [deleting]);

  const authorOptions = useMemo(() => {
    const authors: Array<string> = [];
    docs.forEach((item: DocWithFolderTitle) => {
      if (item.author && !authors.includes(item.author)) {
        authors.push(item.author);
      }
    });
    return authors.map((author: string) => ({ text: author, value: author }));
  }, [docs]);

  const langOptions = useMemo(() => {
    return Object.entries(languageOptions).map(([langCode, langName]) => ({ text: langName, value: langCode }));
  }, []);

  const folderOptions = useFolderColumnFilterItems(folders);

  const { filterFolder, setFilterFolder } = useContext(DocsFilterContext);
  const [filterMediaType, setFilterMediaType] = useState<DocType | null>(null);
  const [filterTitle, setFilterTitle] = useState<string | null>(null);
  const [filterLanguages, setFilterLanguages] = useState<Array<string> | null>(null);
  const [filterAuthors, setFilterAuthors] = useState<Array<string> | null>(null);
  const [filterVisibility, setFilterVisibility] = useState<Visibility | null>(null);

  const hasFilters = useMemo(() => (
    filterFolder || filterMediaType || filterTitle || filterLanguages || filterAuthors || filterVisibility
  ), [filterFolder, filterMediaType, filterTitle, filterLanguages, filterAuthors, filterVisibility]);

  const [isGeneratingCsv, setIsGeneratingCsv] = useState<boolean>(false);
  const exportCsv = useCallback(() => {
    setIsGeneratingCsv(true);

    const header = [
      'Link', 'Title', 'Slug', 'Primary Folder', 'Secondary Folders', 'Tab', 'Type', 'Duration', 'Language', 'Author', 'Visibility', 'Created', 'Updated', 'Media URL', 'Tags', 'Topics', 'Categories', 'Content',
    ];
    const data = docs.map((item) => [
      `https://admin-v2.plumvillage.app/${tab}/docs/${item.id}`,
      item.title,
      item.slug,
      item.folderTitle,
      Object.values(item.folderTitles).map(({ folderTitle }) => folderTitle).join(', '),
      tab as string,
      item.type,
      renderDuration(item.duration),
      languageName(getItemLanguage(item, folders)) || '-',
      item.author || '',
      item.visibility,
      DateTime.fromJSDate(item.createdAt.toDate()).toISO() || '',
      DateTime.fromJSDate(item.updatedAt.toDate()).toISO() || '',
      item.mediaURL || '',
      item.tags || '',
      item.topics?.join(', ') || '',
      item.categories?.join(', ') || '',
      item.content || '',
    ]);

    exportAndDownloadCsv(`pv-app-docs-${tab}-${dayjs().format('YYYY-MM-DD')}`, header, data);
    setIsGeneratingCsv(false);
  }, [folders, docs, tab]);

  if (!tab) {
    return <>Error - no tab active</>;
  }

  return (
    <>
      <Header
        loading={loading}
        tab={tab}
        collection="docs"
        onReload={reloadDocs}
        onAddNew={() => navigate(getEditUrl(tab))}
        onExportCsv={exportCsv}
        isGeneratingCsv={isGeneratingCsv}
      />

      <Table
        loading={loading}
        dataSource={docs}
        rowKey={(item: DocWithFolderTitle) => item.id}
        locale={{ emptyText: hasFilters ? 'Nothing found matching your filters' : (searchText ? 'Nothing found matching your search' : 'Sorry, you do not have any docs (yet).') }}
        onChange={(_pagination, filters) => {
          setFilterFolder(filters.folderId?.[0] as string | null);
          setFilterMediaType(filters.type?.[0] as DocType | null);
          setFilterTitle(filters.title?.[0] as string | null);
          setFilterLanguages(filters.lang as Array<string> | null);
          setFilterAuthors(filters.author as Array<string> | null);
          setFilterVisibility(filters.visibility?.[0] as Visibility | null);
        }}
      >
        <Column
          title="#"
          dataIndex=""
          width={50}
          filteredValue={null}
          render={(_text: string, item: DocWithFolderTitle) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {docs.indexOf(item) + 1}
            </span>
          )}
        />
        <Column
          title=""
          dataIndex="type"
          align="right"
          width={50}
          render={(_text: DocType, item: DocWithFolderTitle) => (
            <FontAwesomeIcon icon={iconsByType[item.type]} />
          )}
          filters={[{
            text: 'Text',
            value: 'text',
          }, {
            text: 'Audio',
            value: 'audio',
          }, {
            text: 'Video',
            value: 'video',
          }]}
          filteredValue={filterMediaType ? [filterMediaType] : null}
          filterMultiple={false}
          onFilter={(value, item: DocWithFolderTitle) => item.type === value}
        />
        <Column
          title="Featured Image"
          dataIndex={'id'}
          width={80}
          render={(id: string, item) => (
            <object
              data={`https://pvappitemimages.b-cdn.net/${id}/thumb@2x.jpg`}
              type="image/jpeg" style={{ width: '60px', height: '60px', minWidth: '60px' }}
            >
              {/* The inner img is a default, if no featured image exists at the above URL */}
              <img src={`/item-${item?.type || 'text'}-default.png`} alt='' style={{ width: '60px', height: '60px', minWidth: '60px' }}/>
            </object>
          )}
        />
        <Column
          title="Title"
          dataIndex="title"
          filterDropdown={filterDropdown('title', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, item) => item
            .title.toLowerCase().includes((value as string).toLowerCase())}
          filterDropdownProps={filterDropdownProps(searchInput)}
          filterMultiple={false}
          filteredValue={filterTitle ? [filterTitle] : null}
          render={(text: string, item: DocWithFolderTitle) => {
            const content = renderHighlight(text);
            return (
              <Link to={getEditUrl(item)}>
                {content}
              </Link>
            );
          }}
          sorter={(a, b) => a.title.localeCompare(b.title)}
        />
        <Column
          title="Folder"
          dataIndex="folderId"
          filters={folderOptions}
          render={(id: string, item: DocWithFolderTitle) => {
            const foldersTitles: Array<{ folderId: string, folderTitle: string }> = [{ folderId: id || '', folderTitle: item.folderTitle }];
            if (item.folders) {
              foldersTitles.push(...Object.entries(item.folderTitles)
                .map(([folderId, { folderTitle }]) => ({ folderId, folderTitle })));
            }
            return (
              <List
                size="small"
                dataSource={foldersTitles}
                renderItem={({ folderId, folderTitle }, index) => (
                  <List.Item title={`ID: ${folderId}`}>
                    {folderTitle}
                    {' '}
                    {foldersTitles.length > 1 && index === 0 && (
                      <Tag>Primary</Tag>
                    )}
                  </List.Item>
                )}
              />
            );
          }}
          filteredValue={filterFolder ? [filterFolder] : null}
          filterMultiple={false}
          filterSearch={true}
          onFilter={(value, item: DocWithFolderTitle) => item.folder === value || typeof item.folders?.[value as string] === 'object'}
        />
        <Column
          title="Language"
          dataIndex="lang"
          filters={langOptions}
          render={(_lang: string, item: DocWithFolderTitle) => languageName(getItemLanguage(item, folders)) || '-'}
          filteredValue={filterLanguages}
          onFilter={(value, item: DocWithFolderTitle) => getItemLanguage(item, folders) === value}
        />
        <Column
          title="Author"
          dataIndex="author"
          filters={authorOptions}
          filterSearch={true}
          filteredValue={filterAuthors}
          render={(text: string) => text || '-'}
          onFilter={(value, item: DocWithFolderTitle) => item.author === value}
        />
        <Column
          title="Visibility"
          dataIndex="visibility"
          filters={[{
            text: 'Public',
            value: 'public',
          }, {
            text: 'Private',
            value: 'private',
          }]}
          filterMultiple={false}
          filteredValue={filterVisibility ? [filterVisibility] : null}
          onFilter={(value, item: DocWithFolderTitle) => item.visibility === value}
          render={(visibility: Visibility) => <VisibilityTag visibility={visibility} />}
        />
        <Column
          title="Created"
          dataIndex="createdAt"
          filteredValue={null}
          render={
            (createdAt: Timestamp) => DateTime.fromJSDate(createdAt.toDate()).toRelative()
          }
          sorter={(
            a: DocWithFolderTitle, b: DocWithFolderTitle,
          ) => a.createdAt.seconds - b.createdAt.seconds}
        />
        <Column
          title="Actions"
          key="actions"
          filteredValue={null}
          render={(_text: string, item: DocObj) => {
            const { id, title } = item;
            const isBeingDeleted = deleting.includes(id);
            const confirmTitle = (
              <span>
                {'Are you sure you want to delete '}
                <strong>{title}</strong>
                ?
              </span>
            );

            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => navigate(getEditUrl(item))}
                  disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={confirmTitle}
                  onConfirm={() => handleDelete(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    size="small"
                    ghost
                    icon={<DeleteOutlined />}
                    loading={isBeingDeleted}
                  />
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default Docs;
