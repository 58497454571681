import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Quote, QuoteInfo } from './QuotesWrapper';
import { Contributor, quotesVariationsUrl } from '../../config';
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, serverTimestamp, setDoc } from 'firebase/firestore';
import { FirestoreCollection, languageNames, quotesGuidelinesUrl, SupportedLanguageCode, supportedLanguageCodes } from '../../config';
import AuthedPage from '../AuthedPage';
import { Helmet } from 'react-helmet-async';
import { Alert, Button, Card, Col, Divider, Form, Input, message, Popconfirm, Row, Select, Space, Spin } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { MinusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QuoteEdit.scss';

type FormErrorsBase = {
  [key in SupportedLanguageCode]?: ({ error?: string, warn?: string } | undefined)[];
};

interface FormErrors extends FormErrorsBase {
  author?: string
  atLeastOneVariation?: string
  sourceURL?: string
}

const cloneQuote: (quote?: Partial<Quote>) => Partial<Quote> = (quote?: Partial<Quote>) => {
  const newQuote = { ... quote };
  if (newQuote) {
    supportedLanguageCodes.forEach(code => {
      const newLanguageData = { ...quote?.[code] };
      if (quote?.[code]?.variations) {
        const newVariations = [...quote[code].variations];
        newLanguageData.variations = newVariations;
        if (newLanguageData.variations) {
          newQuote[code] = newLanguageData as QuoteInfo;
        }
      }
    });
  }
  return newQuote;
};

const QUOTE_MAX_PREFERRED_LENGTH = 100;

const QuoteEdit = () => {
  const itemId = useParams<'itemId'>().itemId;

  const [authors, setAuthors] = useState<Contributor[]>([]);
  const [item, setItem] = useState<Partial<Quote>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const isNew = useMemo(() => itemId === 'new', [itemId]);

  const navigate = useNavigate();

  const addVariantMarkup = useCallback((code: SupportedLanguageCode) =>
    <Button
      type="primary"
      onClick={() => {
        const newItem = cloneQuote(item);
        if (newItem) {
          if (!newItem?.[code]) {
            newItem[code] = { variations: [] };
          }
          if (newItem?.[code] && !newItem[code].variations) {
            newItem[code].variations = [];
          }
          newItem?.[code]?.variations.push('');
        }
        setItem(newItem);

      }}
      disabled={isSaving}
      icon={<PlusOutlined />}
    >
      Add a variant in {languageNames[code]}
    </Button>
  , [isSaving, item]);

  const addAuthorGuidanceMarkup = useMemo(() =>
    <Row>
      <span style={{ flex:1 }}>Don’t see the author you’re looking for? You can add a new contributor on the Contributors page and then return here to associate them with the quote.</span>
      <Button
        type="link"
        onClick={() => {
          navigate('/contributors');
        }}
        disabled={isSaving}
      >
        Go to Contributors page
      </Button>

    </Row>
  , [isSaving, navigate]);


  const initialLoad = useCallback(async () => {
    const currentQuotePromise = new Promise<Partial<Quote>>((resolve, reject) => {
      if (itemId === 'new') {
        resolve({
          id: itemId,
        });
      } else {
        getDoc(doc(collection(getFirestore(), FirestoreCollection.QUOTES), itemId)).then((dataSnapshot) => {
          if (dataSnapshot.metadata.fromCache) {
            throw new Error('No Internet!');
          }
          const quoteData = dataSnapshot.data() as Omit<Quote, 'id'>;
          if (dataSnapshot.exists()) {
            const id = dataSnapshot.id;
            resolve( {
              id: id,
              ...quoteData,
            });
          } else {
            throw new Error('Not found.');
          }
        }).catch((error) => {
          reject(error);
        });
      }
    });

    const [currentMetadata, authorsSnapshot] = await Promise.all([
      currentQuotePromise,
      getDocs(query(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS))),
    ]);

    if (authorsSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }

    const loadingAuthors: Array<Contributor> = [];
    authorsSnapshot.forEach((authorDoc) => {
      const authorData = authorDoc.data() as Omit<Contributor, 'id'>;
      const authorId = authorDoc.id;
      const author: Contributor = {
        id: authorId,
        ...authorData,
      };
      loadingAuthors.push(author);
    });

    setAuthors(loadingAuthors);
    setItem(currentMetadata);
    setIsLoading(false);
  }, [itemId]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const validateForm = useCallback((itemToValidate?: Partial<Quote>) => {
    let formValid = true;
    itemToValidate = itemToValidate ?? item;

    if (!itemToValidate) {

      return false;
    }

    const newFormErrors: FormErrors = {};

    if (!itemToValidate.authorRef) {
      newFormErrors.author = 'Author is required';
      formValid = false;
    }
    if (! itemToValidate.source) {
      newFormErrors.sourceURL = 'Source is required';
      formValid = false;
    }
    let hasAtLeastOneVariation = false;
    supportedLanguageCodes.forEach(code => {
      if (itemToValidate?.[code]?.variations && itemToValidate[code].variations.length > 0) {
        itemToValidate[code].variations.forEach(variation => {
          if (!newFormErrors[code]) {
            newFormErrors[code] = [];
          }
          if (variation.trim().length === 0) {
            newFormErrors[code].push({ error: 'Variation can not be empty' });
            formValid = false;
          } else {
            hasAtLeastOneVariation = true;
            if (variation.trim().length > QUOTE_MAX_PREFERRED_LENGTH) {
              newFormErrors[code].push({ warn: `Quotes longer than ${QUOTE_MAX_PREFERRED_LENGTH} characters are allowed; however, it is preferred to keep them at or below ${QUOTE_MAX_PREFERRED_LENGTH} characters for better readability.` });
            } else {
              newFormErrors[code].push(undefined);
            }
          }
        });
      }
    });
    if (!hasAtLeastOneVariation) {
      formValid = false;
      newFormErrors.atLeastOneVariation = 'Quote is required in at least one language';
    }

    setFormErrors(newFormErrors);

    return formValid;
  }, [item]);

  const save = useCallback(async () => {
    if (!item) {
      return;
    }
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id, authorName, authorRef, createdAt: createdAtTimestamp, source, ...rest
    } = item;
    if (!authorRef || !source) {
      return;
    }
    const createdAt = createdAtTimestamp ? { createdAt: createdAtTimestamp } : { createdAt: serverTimestamp() };
    const data: Omit<Quote, 'id' | 'authorName'> =  {
      ...rest,
      ...createdAt,
      source,
      authorRef,
      updatedAt: serverTimestamp(),
    };
    const quotesCollection = collection(getFirestore(), FirestoreCollection.QUOTES);
    const ref = isNew ? doc(quotesCollection) : doc(quotesCollection, itemId);
    setIsSaving(true);
    try {
      await setDoc(ref, data);
      const updatedQuote: Omit<Quote, 'authorName'> = { id: ref.id, ...data };
      setItem(updatedQuote);
      message.success('Successfully saved quote');
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while saving the quote');
    } finally {
      setIsSaving(false);
      navigate('/quotes/quotes');
    }
  }, [isNew, item, itemId, navigate]);

  const handleSave = useCallback(async () => {
    if (!item || !validateForm()) {
      return;
    }
    save();
  }, [item, save, validateForm]);

  const handleDelete = useCallback(async () => {
    if (!item) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteDoc(doc(collection(getFirestore(), FirestoreCollection.QUOTES), item.id));
      message.success('Successfully deleted the quote.');
      navigate('/quotes/quotes');
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the quote.');
    }
  }, [item, navigate]);

  const saveButtonMarkup = useMemo(() =>
    <Button
      type="primary"
      size="large"
      onClick={handleSave}
      loading={isSaving}
      disabled={isSaving || isDeleting}
    >
      Save
    </Button>, [handleSave, isDeleting, isSaving]);

  const onVariationChange = useCallback((event: ChangeEvent<HTMLInputElement>, code: SupportedLanguageCode, index: number) => {
    const newItem = cloneQuote(item);
    if (newItem && newItem[code]?.variations) {
      newItem[code].variations[index] = event.target.value;
      setItem(newItem);
      validateForm(newItem);
    }
  }, [item, validateForm]);

  const removeVariation = useCallback((code: SupportedLanguageCode, index: number) => {
    const newItem = cloneQuote(item);
    if (newItem?.[code]?.variations && newItem?.[code]?.variations.length >= index + 1) {
      newItem[code].variations.splice(index, 1);
      if (newItem[code].variations.length === 0){
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newItem[code];
      }
      setItem(newItem);
      validateForm(newItem);
    }
  }, [item, validateForm]);

  const onAuthorChange = useCallback((value: string, selected: {
    value: string;
    label: string;
  } | {
    value: string;
    label: string;
  }[] | undefined) => {
    if (!selected || Array.isArray(selected)) {
      return;
    }
    const newItem: Partial<Quote>  = { ...item, authorRef: doc(getFirestore(), FirestoreCollection.CONTRIBUTORS, selected.value) };
    setItem(newItem);
    validateForm(newItem);

  }, [item, validateForm]);

  const onSourceChange = useCallback((value: string, code?: SupportedLanguageCode) => {
    let newItem: Partial<Quote> = { ...item};
    if (code) {
      newItem[code] = {
        ...newItem[code],
        source: value,
      } as QuoteInfo
    } else {
      newItem = { ...item, source: value };
    }
    setItem(newItem);
    validateForm(newItem);
  }, [item, validateForm]);

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      {item && (
        <Helmet>
          <title>{isNew ? 'Add new Quote' : 'Edit Quote'}</title>
        </Helmet>
      )}
      <Spin spinning={isLoading} delay={2000}>
        <div className="DocActionButtons DocActionButtons--top">
          <div className="DocActionButtons__container">
            <h1>{isNew ? 'Add new Quote' : 'Edit Quote'}</h1>
            {saveButtonMarkup}
          </div>
        </div>
        <Divider />
        <div className="DocEdit__container">
          <Form layout="horizontal">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
                <Alert
                  type="info"
                  style={{ marginBottom: 20, flexDirection: 'column' }}
                  message="Please follow our guidelines when adding new or editing existing quotes."
                  action={(
                    <Space direction="vertical">
                      <a href={quotesGuidelinesUrl} target="_blank" rel="noopener noreferrer">
                        Read Guidelines
                        {' '}
                        <FontAwesomeIcon icon="external-link-alt" />
                      </a>
                    </Space>
                  )}
                />
                <Card
                  title="Attribution"
                >
                  <div className='QuoteEdit__description'>
                    <Card.Meta
                      description={addAuthorGuidanceMarkup}
                    />
                  </div>
                  <FormItem label="Author"
                    validateStatus={formErrors.author ? 'error' : ''}
                    help={formErrors.author}
                  >
                    <Select
                      placeholder="Select an author"
                      value={item?.authorRef?.id}
                      onChange={onAuthorChange}
                      status={formErrors.author ? 'error' : ''}
                      style={{ width: '100%' }}
                      options={authors.map((author) => ({
                        value: author.id,
                        label: author.en,
                      }))}
                    />
                  </FormItem>
                  <FormItem
                    label="Source"
                    tooltip="Required. Provide information about where this quote originated. If a URL isn't available, enter a brief description of the source."
                    validateStatus={formErrors.sourceURL ? 'error' : ''}
                    help={formErrors.sourceURL}
                  >
                    <Input.TextArea
                      rows={4}
                      value={item?.source ?? ''}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onSourceChange(event.target.value)}
                      status={formErrors.sourceURL ? 'error' : ''}
                      disabled={isSaving || isDeleting}
                      required
                      placeholder="https://plumvillage.shop/calligraphies/&#13;&#10;Or&#13;&#10;How to Enjoy Life, Dharma talk given by Thay on July 16, 1995 in Lower Hamlet."
                    />
                  </FormItem>
                </Card>
              </Col>
            </Row>
            {
              supportedLanguageCodes.map(code =>
                <Row
                  gutter={{
                    xs: 8, sm: 16, md: 24, lg: 32,
                  }}
                  className='QuoteEdit__language'
                >
                  <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
                    <Card
                      title={languageNames[code]}
                      extra={addVariantMarkup(code)}
                    >
                      <FormItem
                        validateStatus={formErrors.atLeastOneVariation ? 'error' : ''}
                        help={formErrors.atLeastOneVariation}
                      >
                        <div className='QuoteEdit__description'>
                          <Card.Meta
                            description={
                              <>
                                Variations of this quote in {languageNames[code]}. {' '}
                                <a href={quotesVariationsUrl} target="_blank" rel="noopener noreferrer">
                                  Know more about variations
                                  {' '}
                                  <FontAwesomeIcon icon="external-link-alt" />
                                </a>
                              </>
                            }
                          />
                        </div>
                        {
                          (item?.[code]?.variations && item?.[code]?.variations.length > 0 &&
                          <FormItem
                            label="Source"
                            tooltip="Optional. Provide information about where this quote/translation originated. If a URL isn't available, enter a brief description of the source."
                          >
                            <Input.TextArea
                              rows={4}
                              value={item?.[code]?.source ?? ''}
                              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onSourceChange(event.target.value, code)}
                              disabled={isSaving || isDeleting}
                              placeholder="https://plumvillage.shop/calligraphies/&#13;&#10;Or&#13;&#10;How to Enjoy Life, Dharma talk given by Thay on July 16, 1995 in Lower Hamlet."
                            />
                          </FormItem> )
                        }
                        {
                          item?.[code]?.variations.map((variation, index) =>
                            <Row>
                              <Col flex={1}>
                                <FormItem
                                  label={`Variation ${index + 1}`}
                                  tooltip={`Variation ${index + 1} for ${languageNames[code]}`}
                                  validateStatus={formErrors[code]?.[index]?.error ? 'error' : formErrors[code]?.[index]?.warn ? 'warning' : ''}
                                  help={formErrors[code]?.[index]?.error ?? formErrors[code]?.[index]?.warn ?? undefined}
                                >
                                  <Input
                                    value={variation}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                      onVariationChange(event, code, index);
                                    }}
                                    disabled={isSaving || isDeleting}
                                    status={formErrors[code]?.[index]?.error ? 'error' : formErrors[code]?.[index]?.warn ? 'warning' : ''}
                                    showCount
                                  />
                                </FormItem>
                              </Col>
                              <Col>
                                <Button
                                  type="text"
                                  danger
                                  onClick={() => {
                                    removeVariation(code, index);
                                  }}
                                  disabled={isSaving || isDeleting}
                                  icon={<MinusCircleFilled />}
                                  style={{ float: 'right' }}
                                />
                              </Col>
                            </Row>,
                          )
                        }
                      </FormItem>
                    </Card>
                  </Col>
                </Row>,
              )
            }
          </Form>
        </div>
        <Divider />
        <div className="DocActionButtons DocActionButtons--bottom">
          <div className="DocActionButtons__container">
            {!isNew && (
              <Popconfirm
                title={'Are you sure you want to delete this quote?'}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  ghost
                  size="large"
                  loading={isDeleting}
                  disabled={isLoading || isSaving}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
            {saveButtonMarkup}
          </div>
        </div>
      </Spin>
    </AuthedPage>
  );
};

export default QuoteEdit;
