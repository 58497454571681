import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Divider } from 'antd';
import { Helmet } from 'react-helmet-async';

import { getFolderThumbURL } from '../../utils';
import AuthedPage from '../AuthedPage';
import AddThumb from './AddThumb';
import useFolderThumbs from '../../hooks/useFolderThumbs';

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 15px;
`;

const Img = styled.img`
  object-fit: contain;
`;

function FolderThumbs() {
  const [addThumbModalVisible, setAddThumbModalVisible] = useState<boolean>(false);
  const { loading, thumbs } = useFolderThumbs();

  const toggleAddThumbModal = useCallback(() => {
    setAddThumbModalVisible((visible) => !visible);
  }, []);

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Folder Thumbs</title>
      </Helmet>

      <Button
        type="primary"
        onClick={toggleAddThumbModal}
        disabled={loading}
        icon={<PlusOutlined />}
      >
        Add new
      </Button>
      <Divider />
      <Spin spinning={loading}>
        <Thumbnails>
          {thumbs.map((thumb) => (
            <Thumbnail key={thumb} title={`ID: ${thumb}`}>
              <Img src={getFolderThumbURL(thumb)} />
            </Thumbnail>
          ))}
        </Thumbnails>
      </Spin>
      {addThumbModalVisible && (
        <AddThumb
          thumbs={thumbs}
          onDismiss={toggleAddThumbModal}
        />
      )}
    </AuthedPage>
  );
}

export default FolderThumbs;
