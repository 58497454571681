import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Alert, Button, Progress, Steps, Typography,
} from 'antd';
import { collection, doc, getDoc, getFirestore, serverTimestamp, setDoc, Timestamp } from 'firebase/firestore';
import { v5 as uuidv5 } from 'uuid';
import ky from 'ky';
import sanitizeHtml from 'sanitize-html';

import type { DocMetadataObj, DocObj, FileObj, TabValue } from '../../types';
import {
  generateUniqueSlug,
  getDuration,
  getAudioWaveformData,
} from '../../utils';
import { firebaseFunctionsBaseUrl } from '../../config';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

type Podcast = {
  guid: string,
  link: string, // Web page hosting the podcast episode
  title: string,
  description: string,
  author: string,
  date: string,
  tags: string,
  audioURL: string, // URL for the audio file
};

type Props = {
  sourceURL: string,
  sourceName: string,
  tab: TabValue,
  folder: string,
  // eslint-disable-next-line react/require-default-props
  overrideAuthor?: string,
};

const getAllSlugs = async () => {
  const slugsSnapshot = await getDoc(doc(collection(getFirestore(), 'transients'), 'doc-slugs'));
  if (slugsSnapshot.metadata.fromCache) {
    throw new Error('No Internet!');
  }
  return (slugsSnapshot.data() as { list: string[] }).list;
};

export default function PodcastSyncer({
  sourceURL,
  sourceName,
  tab,
  folder,
  overrideAuthor = undefined,
}: Props) {
  const [syncing, setSyncing] = useState(false);
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [syncError, setSyncError] = useState<string>('');
  const [totalPodcasts, setTotalPodcasts] = useState<number>(0);
  const [totalSynced, setTotalSynced] = useState<number>(0);
  const [totalFailed, setTotalFailed] = useState<number>(0);
  const [totalSkipped, setTotalSkipped] = useState<number>(0);
  const [failedUrls, setFailedUrls] = useState<string[]>([]);

  const aborted = useRef<boolean>(false);
  const controllerRef = useRef<AbortController>(new AbortController());
  const finishedSyncing = useMemo(
    () => (totalPodcasts > 0
      ? totalSynced === totalPodcasts
      : false),
    [totalPodcasts, totalSynced],
  );

  const checkIfAborted = useCallback(() => {
    if (aborted.current) {
      throw new Error('USER_ABORTED');
    }
  }, []);

  const handleReset = useCallback(() => {
    aborted.current = true; // abort pending tasks on reset
    setSyncing(false);
    setStep(0);
    setSyncError('');
    setTotalPodcasts(0);
    setTotalSynced(0);
    setTotalFailed(0);
    setTotalSkipped(0);
    setFailedUrls([]);
    controllerRef.current = new AbortController();
  }, []);

  const handleSync = useCallback(async () => {
    handleReset();
    setStep(1);
    aborted.current = false;
    setSyncing(true);
    let podcasts = [];
    try {
      // Making the request using ky instead of invoking the function using firebase
      // as `firebase.functions.httpsCallable` does not support canceling requests.
      const { currentUser } = getAuth();
      if (!currentUser) {
        throw new Error('Not logged in');
      }
      const token = await currentUser.getIdToken();
      const data = await ky.post(`${firebaseFunctionsBaseUrl}/getPodcasts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        json: { url: sourceURL },
        signal: controllerRef.current.signal,
        timeout: false,
      }).json<{ podcasts: Array<Podcast> }>();

      podcasts = data.podcasts;
      setTotalPodcasts(podcasts.length);
    } catch (error) {
      if (!aborted.current) {
        // eslint-disable-next-line no-console
        console.error(error);
        setSyncError(`Oops! Something went wrong while fetching ${sourceName} feed.`);
      }
      setSyncing(false);
      return;
    }

    const slugs = await getAllSlugs();

    setStep(2);

    const actions = podcasts.map(({
      guid,
      link,
      title,
      description,
      author,
      date,
      tags,
      audioURL,
    }, index) => async () => {
      const incrementSyncTotal = () => setTotalSynced((current) => current + 1);

      try {
        checkIfAborted();

        const docId = uuidv5(guid, uuidv5.URL);
        const fileId = uuidv5(audioURL, uuidv5.URL);

        const docSnapshot = await getDoc(doc(collection(getFirestore(), 'docs'), docId));
        if (docSnapshot.exists()) {
          incrementSyncTotal();
          setTotalSkipped((current) => current + 1);
          return;
        }

        const content = sanitizeHtml(description, {
          allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'ul', 'ol', 'li',
            'hr', 'br', 'div', 'b', 'i', 'strong', 'em', 'strike'],
          allowedAttributes: {},
        });

        const fileDocRef = doc(collection(getFirestore(), 'files'), fileId);
        const fileDoc = await getDoc(fileDocRef);

        const storageFilename = `${fileId}.mp3`;
        const storagePath = `media-v2/${storageFilename}`;
        const storageFileRef = ref(getStorage(), storagePath);

        let mediaDownloadUrl;
        let duration;
        let waveformData;

        try {
          mediaDownloadUrl = await getDownloadURL(storageFileRef);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          // the file does not exist, do nothing
        }

        if (fileDoc.exists() && mediaDownloadUrl) {
          const fileData = fileDoc.data() as FileObj;
          duration = fileData.duration;
          waveformData = fileData.waveformData;
        } else {
          const { currentUser } = getAuth();
          if (!currentUser) {
            throw new Error('Not logged in');
          }
          const token = await currentUser.getIdToken();
          /**
           * Download remote media file to firebase storage
           *
           * Making the request using ky instead of invoking the function using firebase
           * as `firebase.functions.httpsCallable` does not support canceling requests.
           * */
          await ky.post(`${firebaseFunctionsBaseUrl}/downloadRemoteMediaFile`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            signal: controllerRef.current.signal,
            json: {
              url: audioURL,
              storagePath,
            },
            timeout: false,
          });

          mediaDownloadUrl = await getDownloadURL(storageFileRef);

          const blob = await ky.get(mediaDownloadUrl, {
            signal: controllerRef.current.signal,
          }).blob();

          const file = new File([blob], storageFilename);
          [duration, waveformData] = await Promise.all([
            getDuration(file, 'audio'),
            getAudioWaveformData(file, 100),
          ]);

          const mediaFile:Omit<FileObj, 'id'> = {
            title,
            name: storageFilename,
            url: mediaDownloadUrl,
            duration,
            waveformData,
            uploadedAt: serverTimestamp() as Timestamp,
          };
          checkIfAborted();
          await setDoc(doc(collection(getFirestore(), 'files'), fileId), mediaFile);
        }

        const item: Omit<DocObj, 'id'> = {
          title,
          slug: generateUniqueSlug(title, slugs),
          content,
          author: overrideAuthor || author,
          tab,
          folder,
          order: index,
          type: 'audio',
          visibility: 'public',
          tags,
          mediaURL: mediaDownloadUrl,
          duration,
          waveformData,
          createdAt: Timestamp.fromDate(new Date(date)) as Timestamp,
          updatedAt: serverTimestamp() as Timestamp,
        };
        checkIfAborted();
        const docRef = doc(collection(getFirestore(), 'docs'), docId);
        await setDoc(docRef, item);

        const itemMetadata: Omit<DocMetadataObj, 'id'> = {
          sourceURL: link,
        };
        await setDoc(doc(collection(docRef, 'metadata'), 'metadata'), itemMetadata);
      } catch (error) {
        if (!aborted.current) {
          // eslint-disable-next-line no-console
          console.error(error);
          setTotalFailed((current) => current + 1);
          setFailedUrls((urls) => [...urls, audioURL]);
        }
      }
      incrementSyncTotal();
    });

    try {
      // We are intentionally running each action in sequence:
      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        // eslint-disable-next-line no-await-in-loop
        await action();
      }
    } catch (error) {
      if (!aborted.current) {
        // eslint-disable-next-line no-console
        console.error(error);
        setSyncError('Oops! Something went wrong while saving podcasts into the database.');
      }
    }
  }, [checkIfAborted, folder, handleReset, overrideAuthor, sourceName, sourceURL, tab]);

  const handleAbort = useCallback(() => {
    controllerRef.current.abort();
    handleReset();
  }, [controllerRef, handleReset]);

  const isStepOne = useMemo(() => step === 1, [step]);
  const isStepTwo = useMemo(() => step === 2, [step]);

  const progressBar = useMemo(() => (
    <Progress
      percent={Math.round(((totalSynced - totalFailed) / totalPodcasts) * 100)}
      status={totalFailed > 0 && finishedSyncing ? 'exception' : 'normal'}
    />
  ), [finishedSyncing, totalFailed, totalPodcasts, totalSynced]);

  return (
    <>
      <h1>{`Import podcasts from ${sourceName}`}</h1>
      <Alert
        message={`Syncing podcasts from ${sourceName}`
        + ' will NOT override previously synced podcasts.'
        + ' It will only fetch items added after last sync.'
        + ' Existing items will be skipped.'}
        type="info"
        showIcon
      />
      <br />
      <br />
      {!(syncing || syncError) && (
        <Button
          type="primary"
          size="large"
          onClick={handleSync}
        >
          Start syncing
        </Button>
      )}
      {(syncing || syncError) && (
        <Steps
          direction="vertical"
          size="small"
          status={syncError ? 'error' : 'wait'}
          current={step - 1}
          items={[
            {
              title: 'Fetching feed',
              description: isStepOne ? syncError : null,
              icon: isStepOne && !syncError ? <LoadingOutlined /> : null,
            },
            {
              title: syncing && step === 2 && totalPodcasts > 0
                ? `Syncing ${totalSynced}/${totalPodcasts} (${totalFailed} failed, ${totalSkipped} skipped)`
                : 'Syncing',
              description: isStepTwo ? syncError || progressBar : null,
              icon: isStepTwo && !finishedSyncing && !syncError ? <LoadingOutlined /> : null,
              status: finishedSyncing ? 'finish' : 'wait',
            },
          ]}
        />
      )}
      {syncing && !syncError && !finishedSyncing && (
        <Button onClick={handleAbort} danger>Abort!</Button>
      )}
      {(syncError || finishedSyncing) && (
        <Button onClick={handleReset}>Reset</Button>
      )}
      {failedUrls.length > 0 && (
        <>
          <br />
          <br />
          <br />
          <Typography.Title level={3}>Error log</Typography.Title>
          {failedUrls.map((url) => (
            <div key={url}>
              <Typography.Text type="danger">
                {`Failed to sync ${url}`}
              </Typography.Text>
            </div>
          ))}
        </>
      )}
    </>
  );
}
