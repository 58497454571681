import { useCallback, useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { message } from 'antd';

import type { UserObj } from '../types';

function useAdminUsers() {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Array<UserObj>>([]);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    try {
      const getAdminUsers = httpsCallable<undefined, Array<UserObj>>(getFunctions(), 'getAllAdminUsers');
      const response = await getAdminUsers();

      setUsers(response.data.map((user: UserObj) => ({
        customClaims: user.customClaims || {},
        ...user,
      })));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while fetching users.');
    }
    setLoading(false);
  }, []);

  useEffect(() => { loadUsers(); }, [loadUsers]);

  const togglePermission = useCallback(async (user: UserObj, permission: string) => {
    setLoading(true);

    try {
      const setAdminPermission = httpsCallable<{ uid: string, permission: string, value: boolean }>(getFunctions(), 'setAdminPermission');
      await setAdminPermission({
        uid: user.uid,
        permission,
        value: !(user.customClaims && user.customClaims[permission]),
      });
      message.success('Successfully updated user permissions.');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while updating user\'s permission.');
    }

    await loadUsers();
    setLoading(false);
  }, [loadUsers]);

  return { loading, users, loadUsers, togglePermission };
}

export default useAdminUsers;
