import { Tabs } from 'antd';
import { FieldValue } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import MetadataApproved from './MetadataApproved';
import { PotentialMetadataConfig } from './MetadataWrapper';
import MetadataPotential from './MetadataPotential';
import { SupportedLanguageCode } from '../../config';

export type MetadataType = {
  id: string,
  slug: string,
  updatedAt?: FieldValue,
  lastUpdatedBy?: 'ADMIN' | 'BATCH',
  en: string
} & Omit<Partial<{ [key in SupportedLanguageCode] : string }>, 'en'>;

export type PotentialMetadataType = {
  id: string,
  topic: string,
  docs: string[]
};

const potentialMetadataConfig: PotentialMetadataConfig = {
  categories: {
    available: false,
  },
  topics: {
    available: true,
  },
};

const Metadata = ({ activeTab, activeChildTab } : { activeTab : 'categories' | 'topics', activeChildTab: | 'approved' | 'potential' }) => {
  const navigate = useNavigate();
  const tabItems = [
    {
      label: `Approved ${activeTab}`,
      key: `${activeTab}-approved`,
      children: (
        <>
          <MetadataApproved
            activeTab={`${activeTab}`}
          />
        </>
      ),
    },
  ];

  if (potentialMetadataConfig[activeTab].available) {
    tabItems.push({
      label: `Potential ${activeTab}`,
      key: `${activeTab}-potential`,
      children: (
        <MetadataPotential
          activeTab={`${activeTab}`}
        />
      ),
    });
  }

  return (
    <>
      <Tabs defaultActiveKey='categories-approved' activeKey={`${activeTab}-${activeChildTab}`} onTabClick={(key: string) => {
        if (key !== activeTab) {
          navigate(`/metadata/${key}`);
        }
      }} items={tabItems} />
    </>
  );
};

export default Metadata;
