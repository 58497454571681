import React, {
  useMemo, useRef, useState, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Alert, Button, Divider, Table,
} from 'antd';
import dayjs from 'dayjs';

import type { CustomerObj } from '../../../types';
import {
  dateFilterOptions, getCustomersMap,
} from '../paymentHelpers';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { PaymentObj } from '../../../types';
import { DownloadOutlined } from '@ant-design/icons';
import { exportAndDownloadCsv } from '../../../csv';

const { Column } = Table;

const countryFilterOptions = [{
  text: 'United States',
  value: 'US',
}, {
  text: 'United Kingdom',
  value: 'GB',
}, {
  text: 'Germany',
  value: 'DE',
}, {
  text: 'France',
  value: 'FR',
}, {
  text: 'Spain',
  value: 'ES',
}, {
  text: 'Italy',
  value: 'IT',
}, {
  text: 'Netherlands',
  value: 'NL',
}, {
  text: 'Sweden',
  value: 'SE',
}, {
  text: 'Ireland',
  value: 'IE',
}, {
  text: 'Switzerland',
  value: 'CH',
}, {
  text: 'Romania',
  value: 'RO',
}, {
  text: 'Australia',
  value: 'AU',
}, {
  text: 'Canada',
  value: 'CA',
}, {
  text: 'Vietnam',
  value: 'VN',
}, {
  text: 'India',
  value: 'IN',
}, {
  text: 'Other',
  value: 'other',
}];
const otherCountries = countryFilterOptions.map((option) => option.value);

/**
 * Internally we use the generic term "Customer" but in the UI we use "Donor"
 */
const Customers = ({ allPayments }: { allPayments: Array<PaymentObj> }) => {
  const [generatingCSV, setGeneratingCSV] = useState<boolean>(false);
  const emailFilterRef = useRef(null);
  const nameFilterRef = useRef(null);

  const customers = useMemo(() => Object.values(getCustomersMap(allPayments)), [allPayments]);

  const exportCSV = useCallback(() => {
    setGeneratingCSV(true);
    const header = [
      'Email', 'Name', 'Country', 'First Payment', 'Last Payment',
      'Is Subscriber?', 'Is Active Subscriber?', 'Number of Donations', 'Total Donation',
    ];
    const data = customers.map(({
      email, name, countryCode, firstPaymentDate, lastPaymentDate,
      isSubscriber, isActiveSubscriber, payments, total,
    }) => [
      email,
      name,
      countryCode,
      dayjs(firstPaymentDate).format('YYYY-MM-DD HH:mm'),
      dayjs(lastPaymentDate).format('YYYY-MM-DD HH:mm'),
      isSubscriber ? 'Yes' : 'No',
      isActiveSubscriber ? 'Yes' : 'No',
      payments.length.toString(),
      total.toFixed(2),
    ]);

    exportAndDownloadCsv(`pv-app-donors-${dayjs().format('YYYY-MM-DD')}`, header, data);
    setGeneratingCSV(false);
  }, [customers]);

  const { filterDropdown, searchFilterIcon, filterDropdownProps } = useSearchFilter();

  return (
    <>
      <Alert
        message={
          'The donor data below only reflects the donations within the selected date range. "Number payments", "Total", "First Payment" and "Last Payment" columns are based on the filtered data and may not count all payments. Click on any donor to see all payments for that donor.'
        }
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />
      <div style={{ textAlign: 'right' }}>
        <Button type="primary" loading={generatingCSV} onClick={exportCSV} icon={<DownloadOutlined />}>
          Export CSV
        </Button>
      </div>
      <Divider />
      <Table
        dataSource={customers}
        rowKey={(customer: CustomerObj) => customer.email}
        locale={{ emptyText: 'Sorry, you do not have any donors (yet).' }}
        scroll={{ x: 800 }}
        summary={(pageData) => {
          let totalCustomers = 0;
          let totalPayments = 0;
          let totalValue = 0;

          pageData.forEach(({ payments, total }) => {
            totalCustomers += 1;
            totalPayments += payments.length;
            totalValue += total;
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={4}>{`Totals for the ${totalCustomers} donors on this page`}</Table.Summary.Cell>
              <Table.Summary.Cell index={2}>{totalPayments}</Table.Summary.Cell>
              <Table.Summary.Cell index={3}>{`$${totalValue.toFixed(2)}`}</Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      >
        <Column
          title="Email"
          dataIndex="email"
          sorter={(a: CustomerObj, b: CustomerObj) => a.email.localeCompare(b.email)}
          render={(email) => (
            <Link to={`/crm/customers/${email}`}>
              {email}
            </Link>
          )}
          filterDropdown={filterDropdown('email', emailFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, customer: CustomerObj) => customer
            .email.toLowerCase().includes((value as string).toLowerCase())}
          filterDropdownProps={filterDropdownProps(emailFilterRef)}
        />
        <Column
          title="Name"
          dataIndex="name"
          sorter={(a: CustomerObj, b: CustomerObj) => a.name.localeCompare(b.name)}
          filterDropdown={filterDropdown('name', nameFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, customer: CustomerObj) => customer
            .name.toLowerCase().includes((value as string).toLowerCase())}
          filterDropdownProps={filterDropdownProps(nameFilterRef)}
        />
        <Column
          title="Country"
          dataIndex="countryCode"
          filters={countryFilterOptions}
          onFilter={(
            value, customer: CustomerObj,
          ) => (value === 'other' ? !otherCountries.includes(customer.countryCode) : customer.countryCode === value)}
        />
        <Column
          title="First Payment"
          dataIndex="firstPaymentDate"
          render={(firstPaymentDate) => dayjs(firstPaymentDate).format('YYYY-MM-DD HH:mm')}
          sorter={(
            a: CustomerObj, b: CustomerObj,
          ) => a.firstPaymentDate.localeCompare(b.firstPaymentDate)}
          defaultSortOrder="descend"
          filters={dateFilterOptions}
          filterMultiple={false}
          onFilter={(value, customer: CustomerObj) => {
            const yesterday = dayjs().startOf('day').subtract(1, value as dayjs.ManipulateType);
            return yesterday.isBefore(dayjs(customer.firstPaymentDate));
          }}
        />
        <Column
          title="Last Payment"
          dataIndex="lastPaymentDate"
          render={(lastPaymentDate) => dayjs(lastPaymentDate).format('YYYY-MM-DD HH:mm')}
          sorter={(
            a: CustomerObj, b: CustomerObj,
          ) => a.lastPaymentDate.localeCompare(b.lastPaymentDate)}
          filters={dateFilterOptions}
          filterMultiple={false}
          onFilter={(value, customer: CustomerObj) => {
            const yesterday = dayjs().startOf('day').subtract(1, value as dayjs.ManipulateType);
            return yesterday.isBefore(dayjs(customer.lastPaymentDate));
          }}
        />
        <Column
          title="Subscription"
          dataIndex="isActiveSubscriber"
          render={(isActiveSubscriber, customer: CustomerObj) => {
            if (isActiveSubscriber) {
              return 'Active';
            }
            return customer.isSubscriber ? 'Inactive' : 'N/A';
          }}
          filters={[
            {
              text: 'Active',
              value: 'active',
            },
            {
              text: 'Inactive',
              value: 'inactive',
            },
            {
              text: 'N/A',
              value: 'no',
            },
          ]}
          onFilter={(
            value, customer: CustomerObj,
          ) => {
            if (value === 'active') {
              return customer.isActiveSubscriber;
            }
            if (value === 'inactive') {
              return customer.isSubscriber && !customer.isActiveSubscriber;
            }
            return !customer.isSubscriber;
          }}
        />
        <Column
          title="Number Payments"
          dataIndex="payments"
          render={(payments) => `${payments.length}`}
          sorter={(a: CustomerObj, b: CustomerObj) => a.payments.length - b.payments.length}
        />
        <Column
          title="Total"
          dataIndex="total"
          render={(total) => `${total.toFixed(2)}`}
          sorter={(a: CustomerObj, b: CustomerObj) => a.total - b.total}
        />
      </Table>
    </>
  );
};

export default Customers;
