import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Spin, Tabs } from 'antd';
import { Helmet } from 'react-helmet-async';
import dayjs, { Dayjs } from 'dayjs';

import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import Payments from './components/Payments';
import Customers from './components/Customers';
import { PaymentObj } from '../../types';
import { loadPayments } from './paymentHelpers';

const CRM = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payments, setPayments] = useState<Array<PaymentObj>>([]);
  const [startDate, setStartDate] = useState<Dayjs | undefined>(() => dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs | undefined>(() => dayjs().endOf('month'));

  useEffect(() => {
    setLoading(true);
    loadPayments(startDate?.toDate(), endDate?.add(1, 'day').toDate()).then((loadedPayments) => {
      setPayments(loadedPayments);
      setLoading(false);
    });
  }, [startDate, endDate]);

  const onChangeStartDate = useCallback((m: Dayjs | null) => {
    setStartDate(m || dayjs().startOf('month'));
  }, []);

  const onChangeEndDate = useCallback((m: Dayjs | null) => {
    setEndDate(m || dayjs().endOf('month'));
  }, []);

  const tabItems = [
    {
      label: 'Donations',
      key: 'payments',
      children: (
        <ErrorBoundary>
          {!loading && <Payments payments={payments} startDate={startDate} endDate={endDate} />}
        </ErrorBoundary>
      ),
    },
    {
      label: 'Donors',
      key: 'customers',
      children: (
        <ErrorBoundary>
          {!loading && <Customers allPayments={payments} />}
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage fullwidth requiredPermission="access_donations">
      <Helmet>
        <title>Donations</title>
      </Helmet>

      <Form initialValues={{ startDate, endDate }}>
        <Form.Item
          label="Start"
          name="startDate"
          tooltip="Recommend to select only a short, recent period, otherwise the page may take too long to load.">
          <DatePicker value={startDate} onChange={onChangeStartDate} />
        </Form.Item>
        <Form.Item
          label="End (inclusive)"
          name="endDate"
          tooltip="Recommend to select only a short, recent period, otherwise the page may take too long to load.">
          <DatePicker value={endDate} onChange={onChangeEndDate} />
        </Form.Item>
      </Form>
      <Spin spinning={loading} delay={200}>
        <Tabs defaultActiveKey="payments" items={tabItems} />
      </Spin>
    </AuthedPage>
  );
};

export default CRM;
